import React, { useEffect} from 'react'
import { useState } from 'react'
import AddButton from '../../../Components/AddButton'
import ProjectsCard from '../../../Components/Cards/ProjectsCard'




const Projects = () => {

  const [data, setData] = useState([])
  useEffect(() => {
    const formData = new FormData()
    formData.append('action', 'select')

    fetch(`${process.env.REACT_APP_BASE_URL}projects.php`,{
      method:'POST',
      body:formData
    })
      .then(res => res.json())
      .then(data => setData(data))
  }, [])

    
  return (
    <div className='w-full max-h-screen flex flex-col items-start justify-start py-6 gap-6 mt-10 lg:mt-0 '>
      <div className='w-full flex items-center justify-between px-6 gap-5'>
        <input type="text" placeholder='Proje Ara' className='flex-1 h-12 rounded-md border border-gray-300 px-4' />
        <AddButton path={'/dashboard/add'}></AddButton>
      </div>
      <div className='w-full h-auto px-6 overflow-y-scroll gap-6 grid  grid-cols-1 lg:grid-cols-3 place-items-center'>
        {
         data &&  data?.map((item,index) => {
          return <ProjectsCard data={item} setData={setData} key={index}></ProjectsCard>
        })
        }
      </div>
    </div>
  )
}

export default Projects