import React from 'react'
import { useEffect, useState } from 'react'
import toast, { Toaster } from 'react-hot-toast';



const AdminAbout = () => {


  const [text, setText] = useState('')
  const [image, setİmage] = useState()
  const [oldİmage, setOldİmage] = useState()
  const [title, setTitle] = useState()
  const [subTitle, setSubTitle] = useState()


  useEffect(() => {
    const formData = new FormData()
    formData.append('action', 'select')
    fetch(`${process.env.REACT_APP_BASE_URL}about.php`, {
      method: 'POST',
      body: formData
    }).then(res => res.json())
      .then(res => {
        setText(res[0].text)
        setOldİmage(res[0].image)
        setTitle(res[0].title)
        setSubTitle(res[0].subtitle)
      })
  }, [])


  const updateHandel = () => {

    const formData = new FormData()
    formData.append('action', 'update')
    formData.append('text', text)
    formData.append('title', title)
    formData.append('subtitle', subTitle)
    image && formData.append('image', image)
    fetch(`${process.env.REACT_APP_BASE_URL}about.php`, {
      method: 'POST',
      body: formData
    }).then(res => res.json())
      .then(res => {
        if (res.status != 200) {
          toast.error(res.message)
        } else {
          toast.success(res.message)
        }
      })
  }


  return (
    <div className='w-full flex lg:flex-row flex-col  items-start gap-4 justify-start p-6 overflow-y-scroll  lg:mt-0 h-screen'>
      <Toaster></Toaster>

      <div className='w-full h-screen flex items-start justify-start gap-4 flex-col'>
        <div className='w-full grid grid-cols-1 gap-4'>
          <div className='w-full flex flex-col items-start justify-start gap-2'>
            <label className='text-base'>
              1.Başlık
            </label>
            <input type='text' onChange={(e) => { setTitle(e.target.value) }} value={title} className='w-full h-12 bg-slate-50 shadow-sm shadow-black/30 p-2 border-2 border-gray-300 rounded-md' />
          </div>
          <div className='w-full flex flex-col items-start justify-start gap-2'>
            <label className='text-base'>
              2.Başlık
            </label>
            <input type='text' onChange={(e) => { setSubTitle(e.target.value) }} value={subTitle} className='w-full h-12 bg-slate-50 shadow-sm shadow-black/30 p-2 border-2 border-gray-300 rounded-md' />
          </div>
        </div>


        <label className='text-base'>
          Hakkımda yazısı
        </label>
        <textarea onChange={(e) => { setText(e.target.value) }} value={text} className='w-full h-3/4 resize-none bg-slate-50 shadow-sm shadow-black/30 p-2 border-2 border-gray-300 rounded-md' placeholder='Hakkımızda' />

      </div>
      <div className='w-full flex flex-col items-center justify-start gap-4'>
        <label className='text-lg text-left lg:text-center w-full'>
          Hakkımızda resimi seçiniz
        </label>
        <div className='w-full h-[1px] bg-black'>

        </div>
        <div className='w-full flex flex-col gap-4'>
          <div className='w-full flex items-start justify-start'>
            <label htmlFor='image'
              className='w-full cursor-pointer h-16 border-dashed border border-black flex items-center justify-center rounded-md hover:bg-slate-100 transition-all'>
              Resim seçiniz
            </label>
            <input id='image' onChange={(e) => { setİmage(e.target.files[0]) }} type='file' className='hidden' />

          </div>
          <div className='max-h-64 h-auto overflow-scroll w-full grid grid-cols-4 gap-4 place-items-center'>
            {
              image && <img className='w-full ' src={URL.createObjectURL(image)}></img>
            }
            {
              oldİmage && <img className='w-full ' src={process.env.REACT_APP_BASE_URL + oldİmage}></img>
            }

          </div>
        </div>
        <div className='w-full flex items-center justify-end'>
          <button onClick={(e) => { updateHandel() }} className='bg-blue-500  hover:bg-blue-700 text-white font-bold py-2 px-4 rounded mt-4'>Kaydet</button>
        </div>

      </div>
    </div>
  )
}

export default AdminAbout