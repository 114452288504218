import { createContext, useState ,useContext } from "react";

const StateContext = createContext({
    currentUser: null,
    token: null,
   
    setUser: () => {},
    setToken: () => {},
    
});


export const ContextProvider = ({ children }) => {

    const [user,_setUser]=useState(localStorage.getItem('user'))
    const [token,_setToken]=useState(localStorage.getItem('token'))
   
    const setUser = (user) => {
        _setUser(user)
        if(user){
            window.sessionStorage.setItem('user',user)
        }else{
            window.sessionStorage.removeItem('user')
        }
    }

    const setToken = (token) => {
        _setToken(token)
        if(token){
            window.sessionStorage.setItem('token',token)
        }else{
            if(user == "" || !user){
                window.sessionStorage.removeItem('token')
            }
        }
    }

  

   

    return(
        <StateContext.Provider value={{
            user,
            setUser,
            token,
            setToken,
         

        }}>
            {children}
        </StateContext.Provider>
    )


}


export const  useStateContext = () => useContext(StateContext)
