import React from 'react'
import { useRef } from 'react'
import { useState ,useEffect} from 'react'
import {BiSolidDownArrow} from 'react-icons/bi'
import { Menu } from '@headlessui/react'
import { Link } from 'react-router-dom'


const Dropdown = ({size,text,link}) => {

    const [data,setData] = useState([])
    useEffect(()=> {
        const formData = new FormData()
        formData.append('action','select')
         fetch(`${process.env.REACT_APP_BASE_URL}projects.php`,{
          method:'POST',
          body:formData
          }).then(res=>res.json())
          .then(res=>{
            setData(res)
          })
      }
      , [])
  
    
  return (
   <>

   <Menu as='a' className='relative'>
    <Menu.Button className='text-white cursor-pointer  flex-col flex items-center justify-center text-sm tracking-wide font-light uppercase'>
        <div className='w-full flex items-center justify-center gap-2'>
            <span className={size?'text-2xl':'text-sm'}>{text}</span>
            <BiSolidDownArrow size={size?24:12}  className='text-white '/>
        </div>
    </Menu.Button>
    <Menu.Items className='hidden lg:flex flex-col absolute  left-0 z-50  bg-[#282828] w-full p-4 gap-4 text-white'>
       
        {
           !link &&  data?.map((item,index)=>{
            return(
                <Menu.Item key={index}>
                    <Link to={'/project/'+item.id} className='w-full whitespace-nowrap cursor-pointer hover:bg-white hover:text-black p-0.5 rounded-sm ' value="1">{
                        item.name.replace('_','\n')
                    }</Link>
                </Menu.Item>
            )
        })
        }
        {
           link == true &&  data?.map((item,index)=>{
            return(
                <Menu.Item key={index}>
                    <a href={item.link} className='w-full cursor-pointer hover:bg-white hover:text-black p-0.5 rounded-sm ' value="1">{
                        item.name.replace('_','\n')
                    }</a>
                </Menu.Item>
            )
        })
        }
    </Menu.Items>
    <Menu.Items className='lg:hidden flex flex-col   bg-[#282828] w-full p-4 gap-4 text-white'>
        {
            !link && data?.map((item,index)=>{
                return(
                    <Menu.Item key={index}>
                        <Link to={'/project/'+item.id} className='w-full cursor-pointer hover:bg-white hover:text-black p-0.5 rounded-sm ' value="1">{
                          item.name.replace('_','\n')
                        }</Link>
                    </Menu.Item>
                )
            })
        }
        {
            link == true && data?.map((item,index)=>{
                return(
                    <Menu.Item key={index}>
                        <Link to={'/project/'+item.id} className='w-full cursor-pointer hover:bg-white hover:text-black p-0.5 rounded-sm ' value="1">{
                        'dsad'
                        }</Link>
                    </Menu.Item>
                )
            })
        }
    </Menu.Items>
   </Menu>
   
    
    
   

   </>
  )
}

export default Dropdown