import React, { useEffect, useState } from 'react'
import Navbar from '../Components/Navbar'
import { motion } from 'framer-motion'
import { Link } from 'react-router-dom'
const Gallery = () => {

    const [data, setData] = useState([])
    useEffect(() => {

        const formData = new FormData()
        formData.append('action', 'select')
        fetch(`${process.env.REACT_APP_BASE_URL}projects.php`, {
            method: 'POST',
            body: formData
        }).then(res => res.json())
            .then(data => {
                setData(data)
                console.log(data)
            })
    }, [])


    return (
        <div className='flex w-full items-start justify-between flex-col h-screen '>
            <Navbar></Navbar>


            <div className='w-full flex flex-wrap lg:items-start lg:justify-start items-center justify-center place-items-start p-3 gap-4 h-screen mt-10'>
                {
                    data.map((item, index) => {
                        let firstİmage;

                        item.images.includes(',') ? firstİmage = item.images.split(',')[1] : firstİmage = item.images
                        return (
                            <Link to={'/gallery/'+item.id} className='w-96 shadow-black drop-shadow-md hover:drop-shadow-2xl flex h-1/2 bgtr aspect-square relative group '>
                                <div className='w-full flex flex-col gap-2 items-center group-hover:z-10 transition-all text-white text-2xl uppercase justify-center bg-black/50 absolute top-0 left-0 h-full -z-10 '>
                                    <h1>{item.name}</h1>
                                    <h4 className='text-base'>İncele</h4>
                                </div>
                                <img src={process.env.REACT_APP_BASE_URL + firstİmage} className='w-full h-full md:object-cover object-cover '></img>
                            </Link>)
                    })
                }
            </div>

        </div>
    )
}

export default Gallery