import React, { useEffect } from 'react'
import logo from '../Assets/logo.jpg'
import { useState } from 'react'
import {AiOutlinePhone,AiOutlineMail,AiFillFacebook,AiFillInstagram} from 'react-icons/ai'
const Footer = () => {

    const [data,setData] = useState([])
    useEffect(() => {
        const fData = new FormData()
        fData.append('action','select_contact')
         fetch(`${process.env.REACT_APP_BASE_URL}contact.php`,{
          method:'POST',
          body:fData
          }).then(res=>res.json())
          .then(res=>{
           setData(res)
           
           
          })
    }
        , [])
  return (
    <footer className='w-full bg-[#282828] h-auto flex flex-col py-6 gap-6 lg:flex-row lg:gap-0 lg:py-0  items-center justify-between lg:h-32 px-12'>
        <img src={logo} alt="Logo" className='h-16 '/>
        <div className='text-white grid grid-cols-2 place-items-start gap-3'>
            <a href={'tel:+0'+data?.phone} className='flex items-center justify-center gap-2 group hover:text-orange-500'>
                <AiOutlinePhone className='group-hover:text-orange-500' size={24}/>
                Bizi arayın
            </a>
            <a href={'mailto:'+data?.mail} className='flex items-center justify-center gap-2 group hover:text-orange-500'>
                <AiOutlineMail className='group-hover:text-orange-500' size={24}/>
                Bize yazın
            </a>
            <a href={data?.facebook} className='flex items-center justify-center gap-2 group hover:text-orange-500'>
                <AiFillFacebook className='group-hover:text-orange-500' size={24}/>
                megaadainsaat
            </a>
            <a href={data?.instagram} className='flex items-center justify-center gap-2 group hover:text-orange-500'>
                <AiFillInstagram className='group-hover:text-orange-500' size={24}/>
                megaadainsaat
            </a>

        </div>
    </footer>           
  )
}

export default Footer