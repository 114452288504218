import React, { useEffect, useSyncExternalStore } from 'react'
import { AiFillYoutube } from 'react-icons/ai'
import { useState ,useRef } from 'react'
import toast, { Toaster } from 'react-hot-toast';
import {Grid} from 'react-loader-spinner';
import {BsBook} from 'react-icons/bs'
import { Editor } from 'primereact/editor';
import {FaMapMarkerAlt} from 'react-icons/fa'
import { InputSwitch } from 'primereact/inputswitch';
import "primereact/resources/themes/lara-light-indigo/theme.css";




const AddProjects = () => {

    const resimContainer = useRef()
    const planContainer = useRef()
    const [name, setName] = useState('')
    const [description, setDescription] = useState('')
    const [features, setFeatures] = useState('')
    const [konum, setKonum] = useState('')
    const [youtube, setYoutube] = useState('')
    const [link, setLink] = useState('')
    const [resim, setResim] = useState([])
    const [plan, setPlan] = useState([])
    const [checked, setChecked] = useState(true);    
    const loader = useRef()

    const submitHandel = () => {
      if(resim.length > 100){
            alert('5 den fazla resim yükleyemezsiniz')
            return
        }
        if(plan.length > 100){
            alert('2 den fazla plan yükleyemezsiniz')
            return
        }
        else{
            loader.current.classList.remove('hidden')
            loader.current.classList.add('flex')
            const formData = new FormData()
            formData.append('action', 'add')
            formData.append('name', name)
            formData.append('description', description)
            formData.append('features', features)
            formData.append('konum', konum)
            formData.append('youtube', youtube)
            formData.append('checked', checked?1:0)
            formData.append('link', link)
            
    
            for(let i=0;i<resim.length;i++){
                formData.append(`resim[${i}]`,resim[i])
            }
    
            for(let i=0;i<plan.length;i++){
                formData.append(`plan[${i}]`,plan[i])
            }
    
    
            fetch(`${process.env.REACT_APP_BASE_URL}projects.php`,{
                method:'POST',
                body:formData
            })
            .then(res=>res.json())
            .then(data=>{
                loader.current.classList.add('hidden')
                loader.current.classList.remove('flex')
                if(data.status === 200){
                    toast.success(data.message)
                }
                else{
                    toast.error(data.message)
                }
            }
                
            )

            
        }

    }
    useEffect(() => {
        if (resim.length > 0) {
            for (let i = 0; i < resim.length; i++) {
                const reader = new FileReader()
                reader.onload = function (e) {
                    const img = document.createElement('img')
                    img.src = e.target.result
                    img.className = 'w-32 h-32 object-cover'
                    resimContainer.current.appendChild(img)
                    img.addEventListener('click', () => {
                        resimContainer.current.removeChild(img)
                        
                    })
                }
                reader.readAsDataURL(resim[i])
            }
        }
        else {
            
        }

    }, [resim])
    useEffect(() => {
        if (plan.length > 0) {
            for (let i = 0; i < plan.length; i++) {
                const reader = new FileReader()
                reader.onload = function (e) {
                    const img = document.createElement('img')
                    img.src = e.target.result
                    img.className = 'w-32 h-32 object-cover'
                    planContainer.current.appendChild(img)
                    img.addEventListener('click', () => {
                        planContainer.current.removeChild(img)
                       
                    })
                }
                reader.readAsDataURL(plan[i])
            }
        }
        else {
            
        }

    }, [plan])


    useEffect(() => {
        console.log(checked)
    }
    , [checked])
    
   useEffect(() => {
    console.log(resim)
    }
    , [resim])
   
   
    return (
        <div className='w-full h-screen flex flex-col lg:flex-row  items-start justify-start mt-12 lg:mt-0'>
            <Toaster position='bottom-center'/>
            <div className='w-full min-h-screen z-[999999999999] hidden fixed top-0 left-0 bg-white  items-center justify-center' ref={loader}>
            <Grid
                color='red'
            ></Grid>
            </div>
            <div className='lg:w-1/2 w-full h-full lg:overflow-y-scroll  border-r border-slate-300 flex flex-col gap-4 p-6'>
            <div className='flex w-full border-b border-slate-200 p-2  items-start justify-between gap-1'>
                    <label>
                        Satış Durumu
                    </label>
                    <div className='flex items-center justify-center gap-4'>
                    
                    <InputSwitch defaultChecked checked={checked} onChange={(e) => setChecked(e.value)} />

                    
                    </div>
                </div>
                <div className='flex w-full flex-col items-start justify-start gap-1'>
                    <label>
                        Proje Adı
                    </label>
                    <input onChange={(e) => { setName(e.target.value) }} value={name} className='w-full h-12 rounded-md border border-gray-300 p-4' />
                </div>
                
                
                <div className='w-full flex flex-col items-start justify-start gap-1'>
                    <label>
                        Proje Açıklaması
                    </label>
                    <Editor  value={description} onTextChange={(e) => setDescription(e.htmlValue)} style={{ height: '200px' }} />

                </div>
                <div className='w-full flex flex-col items-start justify-start gap-1'>
                    <label>
                        Proje Özellikleri
                    </label>
                    <Editor  value={features} onTextChange={(e) => setFeatures(e.htmlValue)} style={{ height: '200px' }} />

                </div>
                <div className='w-full flex  items-start justify-start gap-4'>
                    <FaMapMarkerAlt size={48} color='blue' />
                    <input onChange={(e) => { setKonum(e.target.value) }} value={konum} className='w-full h-12 rounded-md border border-gray-300 p-4' />
                </div>
                <div className='w-full flex  items-start justify-start gap-4'>
                    <AiFillYoutube size={48} color='red' />
                    <input onChange={(e) => { setYoutube(e.target.value) }} value={youtube} className='w-full h-12 rounded-md border border-gray-300 p-4' />
                </div>
                <div className='w-full flex  items-start justify-start gap-4'>
                    <BsBook size={48} />
                    <input onChange={(e) => { setLink(e.target.value) }} value={link} className='w-full h-12 rounded-md border border-gray-300 p-4' />
                </div>
                <button onClick={(e) => { submitHandel(e) }} className='w-full lg:flex hidden text-white text-xl font-light uppercase rounded-md items-center justify-center h-16 p-2 bg-indigo-600'>
                    Kaydet
                </button>
            </div>



            <div className=' w-full lg:w-1/2 mt-80 lg:mt-0 h-full flex-col gap-8 p-6 items-start justify-start'>
                <div className='w-full flex flex-col items-center justify-start gap-4'>
                    <label className='text-lg text-left lg:text-center w-full'>
                        Proje Resimleri
                    </label>
                    <div className='w-full h-[1px] bg-black'>

                    </div>
                    <div className='w-full flex flex-col gap-4'>
                        <div className='w-full flex items-start justify-start'>
                            <label htmlFor='plan'
                                className='w-full cursor-pointer h-16 border-dashed border border-black flex items-center justify-center rounded-md hover:bg-slate-100 transition-all'>
                                Plan görselleri seçmek için tıklayın
                            </label>
                            <input multiple onChange={(e) => { setPlan(e.target.files) }} type='file' id='plan' className='hidden' />
                        </div>
                        <div ref={planContainer} className='max-h-64 h-auto overflow-scroll w-full grid grid-cols-4 gap-4 place-items-center'>
                           

                        </div>
                    </div>
                </div>
                <div className='w-full mt-6 flex flex-col items-center justify-start gap-4'>

                    <div className='w-full flex flex-col gap-4'>
                        <div className='w-full flex items-start justify-start'>
                            <label htmlFor='resim'
                                className='w-full cursor-pointer h-16 border-dashed border border-black flex items-center justify-center rounded-md hover:bg-slate-100 transition-all'>
                                Binanın görselleri seçmek için tıklayın
                            </label>
                            <input multiple onChange={(e) => { setResim(e.target.files) }} type='file' id='resim'  className='hidden' />
                        </div>
                        <div ref={resimContainer} className='max-h-64 h-auto overflow-scroll w-full grid grid-cols-4 gap-4 place-items-center'>
                        </div>

                    </div>
                </div>
                <button onClick={(e) => { submitHandel(e) }} className='w-full lg:hidden flex mt-5 text-white text-xl font-light uppercase rounded-md items-center justify-center h-16 bg-indigo-600'>
                    Kaydet
                </button>

            </div>


        </div>
    )
}

export default AddProjects