import React from 'react'
import { Link } from 'react-router-dom'
import { useState  } from 'react'
import { useNavigate } from 'react-router-dom'
import { useStateContext } from '../../Context/ContextProvider'
import toast, { Toaster } from 'react-hot-toast';

const Login = () => {

  const navigate = useNavigate()
  const { setId, setToken , setUser } = useStateContext()
  const [username, setUsername] = useState('')
  const [password, setPassword] = useState('')

  const loginHandel = (e)=>{
    e.preventDefault()
    const formData = new FormData()
    formData.append('action','login')
    formData.append('username',username)
    formData.append('password',password)
    fetch(`${process.env.REACT_APP_BASE_URL}auth.php`,{
      method:'POST',
      body:formData
      }).then(res=>res.json())
      .then(res=>{
        if(res.status === 200){
          toast.success('Giriş Başarılı')
          setUser(res.token)
          setToken(res.token)
          navigate('/dashboard/projects')

        }else{
          toast.error('Giriş Başarısız')
        }
        
      })
  }

  return (
    <div className='w-full h-screen flex items-center justify-center'>
      <Toaster position='bottom-center' />
        <div className='lg:w-1/3 w-full h-full flex flex-col items-center justify-center px-12 lg:p-4 gap-5 '>
            <h1 className='text-4xl text-left w-full uppercase tracking-[5px] font-light text-slate-600'>
                Yönetim Paneli
            </h1>
            <div className='h-0.5 w-full bg-slate-400 ' />
            <input onChange={(e)=>{setUsername(e.target.value)}} value={username} type="text" placeholder='Kullanıcı Adı' className='w-full h-12 bg-transparent border-2  p-2 rounded-sm outline-none focus:border-slate-500' />
            <input onChange={(e)=>{setPassword(e.target.value)}} value={password} type="password" placeholder='Şifre' className='w-full h-12 bg-transparent border-2  p-2 rounded-sm outline-none focus:border-slate-500' />
            <button onClick={(e)=>{loginHandel(e)}}  className='w-full h-12 flex items-center justify-center bg-indigo-600 text-white uppercase tracking-[5px] font-light rounded-md'>Giriş Yap</button>

            
        </div>
    </div>
  )
}

export default Login