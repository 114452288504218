import React from 'react'
import DashboardSidebar from '../../Components/DashboardSidebar'
import { Outlet } from 'react-router-dom'

export const Dashboard = () => {
  return (
    <div className='w-full min-h-screen flex items-start justify-start'>
        <DashboardSidebar></DashboardSidebar>
        <Outlet></Outlet>
    </div>
  )
}
