import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import toast, { Toaster } from 'react-hot-toast';


const ProjectsCard = ({ data, setData }) => {

    const [images, setİmages] = useState(data.images.split(','))
    const list = React.createRef()
    const deleteHandel = (id) => {
        const formData = new FormData()
        formData.append('action', 'delete')
        formData.append('id', id)

        fetch(`${process.env.REACT_APP_BASE_URL}projects.php`, {
            method: 'POST',
            body: formData
        })
            .then(res => res.json())
            .then(res => {
                if (res.status != 200) {
                    toast.error(res.message)
                } else {
                    window.location.reload()
                }
            })
    }


    return (
        <div className='w-full h-auto flex items-start justify-start flex-col shadow-sm shadow-black/30'>
            <Toaster></Toaster>
            <img src={process.env.REACT_APP_BASE_URL + images[0]} alt="" className='w-full h-1/2 max-h-80 box object-cover ' />
            <div className='max-h-[50%] h-auto w-full flex flex-col items-center justify-center gap-4 p-4  '>
                <div className='w-full flex flex-col items-start justify-start gap-4'>
                    <h1 className='text-2xl'>
                        {data.name}
                    </h1>


                </div>
                <div className='w-full flex items-center justify-center gap-4'>
                    <Link to={'/dashboard/edit/' + data.id} className='w-1/2 rounded-md p-2 text-center bg-indigo-600 text-white hover:bg-indigo-700 transition-all'>
                        Düzenle
                    </Link>
                    <button onClick={(e) => { deleteHandel(data.id) }} className='w-1/2 rounded-md p-2 bg-red-600 text-white hover:bg-red-700 transition-all'>
                        Sil
                    </button>
                </div>


            </div>

        </div>
    )
}

export default ProjectsCard