import React from 'react'
import { Link } from 'react-router-dom'
import { AiOutlinePlus } from 'react-icons/ai'


const AddButton = ({path}) => {
    return (
        <Link to={path} className='group text-3xl flex gap-3 items-center hover:w-32 transition-all justify-center w-12  h-12  bg-indigo-600 text-white p-2 rounded-full shadow-lg hover:bg-indigo-700'>
            <AiOutlinePlus size={20}></AiOutlinePlus>
            <a className='hidden group-hover:inline-block transition-all duration-500 text-sm'>
                İlan Ekle
            </a>
        </Link>
    )
}

export default AddButton